import FileSaver from 'file-saver';
import { setCurrentSignedDocuments } from 'store/actions/docs/docsActions';
import CertificateApi from '../../api/CertificateApi';
import DocumentsApi from '../../api/DocumentsApi';
import ExamDocApi from '../../api/ExamDocApi';
import NavigationExamApi from '../../api/NavigationExamApi';
import TransactionApi from '../../api/TransactionApi';
import ApiCaller from '../../lib/ApiCaller';
import MYDocument from 'lib/common/models/myDocument';

/**
 * @deprecated not used, requires replacing slug by MYDocument id
 */
export const sendToWriteDoc = (base: string, slug: string) => {
  const legalAPI = new ExamDocApi(new ApiCaller());
  legalAPI
    .sendToWriteDoc(base, slug)
    .then((file: any) => {
      FileSaver.saveAs(file, slug + '.doc');
    })
    .catch((err) => {
      console.error(err);
    });
};

export const sendToWritePDF = async (document: MYDocument, base?: string) => {
  const examDocApi = new ExamDocApi(new ApiCaller());
  try {
    const file = await examDocApi.sendToWritePDF(document.id, base);
    if(file) FileSaver.saveAs(file, document.sourceSlug + '.pdf');
    return true;
  } catch (error) {
    console.error("Error on ApiCommands.sendToWritePDF");
    console.error(error);
    return false;
  }
};

export const downloadExamByNavigationExamId = (
  navigationExamId: string,
  displayName = 'exam',
  token: string,
) => {
  const examApi = new ExamDocApi(new ApiCaller(token));
  examApi
    .downloadExamByNavigationExamId(navigationExamId)
    .then((file) => {
      FileSaver.saveAs(file, `${displayName}.pdf`);
    })
    .catch((err) => {
      console.error(err);
      alert(err.message);
    });
};

export const downloadExamByDocumentDisplayName = (name: string, displayName = 'exam') => {
  const examApi = new ExamDocApi(new ApiCaller());
  examApi
    .downloadExamByDocumentDisplayName(name)
    .then((file) => {
      FileSaver.saveAs(file, `${displayName}.pdf`);
    })
    .catch((err) => {
      console.error(err);
      alert(err.message);
    });
};

export const downloadDocument = (hash: string, displayName = 'file.pdf', token?: string) => {
  const documentApi = new DocumentsApi(new ApiCaller(token));
  documentApi.downloadDocumentByHash(hash).then((file) => {
    FileSaver.saveAs(file, `${displayName}`);
  });
};

export const downloadMarkerReportByExamId = (
  id: string,
  displayName = 'report',
  token: string,
) => {
  const examApi = new DocumentsApi(new ApiCaller(token));
  examApi
    .downloadMarkerReportByExamId(id)
    .then((file) => {
      return FileSaver.saveAs(file, `${displayName}`);
    })
    .catch((err) => {
      console.error(err);
      alert(err.message);
    });
};

export const downloadExamSchedules = (
  token: string,
) => {
  const documentsApi = new DocumentsApi(new ApiCaller(token));
  documentsApi
    .downloadExamSchedules()
    .then((file) => {
      return FileSaver.saveAs(file, 'Exam_schedules.pdf');
    })
    .catch((err) => {
      console.error(err);
      alert(err.message);
    });
};

export const downloadAnswers = (id: string, displayName = 'answers') => {
  const examApi = new ExamDocApi(new ApiCaller());
  examApi
    .downloadAnswers(id)
    .then((file) => {
      FileSaver.saveAs(file, `ANSWERS_${displayName}.pdf`);
    })
    .catch((err) => {
      console.error(err);
      alert(err.message);
    });
};

export const createPDFWithCertificate = (
  sending: Function,
  base: string,
  documentId: string
) => {
  sending(true);
  const legalAPI = new ExamDocApi(new ApiCaller());
  legalAPI
    .createPDFWithCertificate(base, documentId)
    .then((file: any) => {
      sending(false);
    })
    .catch((err) => {
      console.error(err);
    });
};

export const updateCertificateList = async (
  dispatch: Function,
  userId: string,
) => {
  const legalAPI = new ExamDocApi(new ApiCaller());
  const items = await legalAPI.getCertificatesRaw(userId);
  dispatch(setCurrentSignedDocuments(items));
};

export const downloadTransactionReceipt = (transaction: any) => {
  const transactionApi = new TransactionApi(new ApiCaller());
  transactionApi
    .getReceipt(transaction._id)
    .then((file) => {
      FileSaver.saveAs(file, transaction._id + '.pdf');
    })
    .catch((err) => {
      console.error(err);
    });
};

export const downloadInvoiceReceipt = (exam: any, token: string) => {
  const navigationExamApi = new NavigationExamApi(new ApiCaller(token));
  navigationExamApi
    .getReceipt(exam.id)
    .then((file) => {
      if (file.error) throw Error(file.error)
      FileSaver.saveAs(file, exam.id + '.pdf');
    })
    .catch((err) => {
      console.error(err);
      alert(err.message)
    });
};

export const downloadCertificateInvoiceReceipt = (certificateId: string, token: string, invoiceNumber?: string,transactionId?:string) => {
  const certificateApi = new CertificateApi(new ApiCaller(token));
  console.log("certificateId", certificateId, invoiceNumber, transactionId);
  certificateApi
    .getReceipt(certificateId, invoiceNumber,transactionId)
    .then((file) => {
      if (file.error) throw Error(file.error)
      FileSaver.saveAs(file, certificateId + '.pdf');
    })
    .catch((err) => {
      console.error(err);
      alert(err.message)
    });
};

export const downloadCertificate = (id: string, token: string) => {
  const certificateApi = new CertificateApi(new ApiCaller(token));
  certificateApi.downloadCertificate(id).then((file) => {
    FileSaver.saveAs(file, `${id}.pdf`);
  });
};

export const downloadPreviewCertificate = (gradeId: string, token: string) => {
  const certificateApi = new CertificateApi(new ApiCaller(token));
  certificateApi.getCertificatePreview(gradeId).then((file) => {
    FileSaver.saveAs(file, `${gradeId}.pdf`);
  });
};
