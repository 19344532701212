import ApiCaller from '../lib/ApiCaller';
import ExamCertificate from '../lib/common/models/examCertificate';
import ExamCertificateCollection from '../lib/common/models/examCertificateCollection';

class CertificateApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getAll(): Promise<ExamCertificateCollection> {
    return this.apiCaller
      .call('/v1/certificate/', 'GET', undefined)
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        const apiArr = data.certificates.map(
          (certificate: ExamCertificate) => new ExamCertificate(certificate),
        );
        return new ExamCertificateCollection(apiArr);
      });
  }

  getAllByCandidate(candidateId: string): Promise<ExamCertificateCollection> {
    return this.apiCaller
      .call(`/v1/certificate/candidate/${candidateId}`, 'GET', undefined)
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        const apiArr = data.certificates.map(
          (certificate: ExamCertificate) => new ExamCertificate(certificate),
        );
        return new ExamCertificateCollection(apiArr);
      });
  }

  revoke(id: string, token?: string): Promise<ExamCertificate> {
    return this.apiCaller
      .call(`/v1/certificate/revoke/${id}`, 'POST', undefined, token)
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return new ExamCertificate(data);
      });
  }

  reissue(id: string, token?: string): Promise<ExamCertificate> {
    return this.apiCaller
      .call(`/v1/certificate/reissue/${id}`, 'POST', undefined, token)
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return new ExamCertificate(data);
      });
  }

  generateCertificate(
    gradeId: string,
    token?: string,
  ): Promise<ExamCertificate> {
    return this.apiCaller
      .call('/v1/certificate/generate/grade/' + gradeId, 'POST', {}, token)
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }

        return new ExamCertificate(data);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getCertificatePreview(gradeId: string): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/certificate/preview/grade/' + gradeId, 'GET')
      .then((file) => {
        return file;
      });
  }

  downloadCertificate(id: string): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/certificate/download/' + id, 'GET')
      .then((file) => {
        return file;
      });
  }

  getReceipt(certificateId: string, invoiceNumber?: string, transactionId?:string): Promise<void | any | undefined> {

    const path = `/v1/certificate/create-receipt/${certificateId}${invoiceNumber ? '/invoice/' + invoiceNumber : ''}${transactionId ? '/transaction/' + transactionId : ''}`;
    return this.apiCaller
              .download(path, 'GET')
      .then((file) => {
        return file;
      });
  }
}

export default CertificateApi;
