import CandidateCollection from "./candidateCollection";
import User, { UserTypesLabels } from "./user";
import Syllabus from "./syllabus";
import Module from "./module";
// @ts-ignore
import moment from "moment";
import ExamCenter from "./examCenter";

export enum ScriptIntentionTypes {
  SCAN = "SCAN",
  COURIER = "COURIER"
}

export const ScriptIntentions = {
  [ScriptIntentionTypes.SCAN]: "Scan",
  [ScriptIntentionTypes.COURIER]: "Courier",
}

export enum NavigationExamStatusLabels {
  SCHEDULED = "SCHEDULED",
  CLOSED = "CLOSED",
  CANCELLED = "CANCELLED",
  GENERATED = "GENERATED",
  SUBMITTED = "SUBMITTED",
  REVISION = "REVISION",
  REVISIONMCA = "REVISIONMCA",
  RELEASED = "RELEASED"
}

export const NavigationExamStatus = {
  [NavigationExamStatusLabels.SCHEDULED]: "Scheduled",
  [NavigationExamStatusLabels.CLOSED]: "Closed",
  [NavigationExamStatusLabels.CANCELLED]: "Cancelled",
  [NavigationExamStatusLabels.GENERATED]: "Generated",
  [NavigationExamStatusLabels.SUBMITTED]: "Marks submitted",
  [NavigationExamStatusLabels.REVISION]: "Awaiting Second Marker",
  [NavigationExamStatusLabels.REVISIONMCA]: "Awaiting MCA",
  [NavigationExamStatusLabels.RELEASED]: "Released"
}

export interface IExamPaymentSummary {
  items: any[],
  subtotal: number,
  requestDate: string,
  invoiceDate?: string;
  timestamp: string,
  exam: {
    id: string,
    number: string,
    date: string
  },
  center: {
    name: string,
    address: string
  }
}

interface NavigationExamInfo {
  syllabus: any,
  module: any,
  date: Date,
  numCandidates?: string,
  scriptIntention: string,
  id?: string;
  _id?: string;
  status: string;
  createdBy?: any;
  createdOn?: string;
  updatedBy?: string;
  updatedOn?: string;
  firstMarker?: any;
  secondMarker?: any;
  candidates?: CandidateCollection;
  examCenterId?: any;
  displayName: string;
  sequence: number;
  invoiceSequence: number;
  cancellationDate?: Date;
  examId?: any;
  documentSerialNumber?: string;
}

class NavigationExam {
  syllabus: any;
  module: any;
  date: Date;
  numCandidates?: string;
  scriptIntention: string;
  id?: string;
  status: string;
  createdBy?: any;
  createdOn?: string;
  updatedBy?: string;
  updatedOn?: string;
  firstMarker?: any;
  secondMarker?: any;
  candidates?: CandidateCollection | string[];
  examCenterId?: ExamCenter;
  displayName: string;
  sequence: number;
  invoiceSequence: number;
  cancellationDate?: Date;
  examId?: any;
  documentSerialNumber?: string;

  constructor(navigationExamInfo: NavigationExamInfo) {
    this.id = navigationExamInfo.id ? navigationExamInfo.id : navigationExamInfo._id;
    this.syllabus = navigationExamInfo.syllabus;
    this.module = navigationExamInfo.module;
    this.date = navigationExamInfo.date;
    this.numCandidates = navigationExamInfo.numCandidates;
    this.scriptIntention = navigationExamInfo.scriptIntention;
    this.status = navigationExamInfo.status;
    this.createdBy = navigationExamInfo.createdBy;
    this.createdOn = navigationExamInfo.createdOn;
    this.updatedBy = navigationExamInfo.updatedBy;
    this.updatedOn = navigationExamInfo.updatedOn;
    this.firstMarker = navigationExamInfo.firstMarker;
    this.secondMarker = navigationExamInfo.secondMarker;
    this.candidates = navigationExamInfo.candidates;
    this.examCenterId = navigationExamInfo.examCenterId;
    this.displayName = navigationExamInfo.displayName;
    this.sequence = navigationExamInfo.sequence;
    this.invoiceSequence = navigationExamInfo.invoiceSequence;
    this.cancellationDate = navigationExamInfo.cancellationDate;
    this.examId = navigationExamInfo.examId;
    this.documentSerialNumber = navigationExamInfo.documentSerialNumber;
  }

  load(data: any): NavigationExam {
    return new NavigationExam(data);
  }

  toObject(): any {
    return {
      id: this.id,
      syllabus: this.syllabus,
      module: this.module,
      date: this.date,
      numCandidates: this.numCandidates,
      scriptIntention: this.scriptIntention,
      status: this.status,
      createdBy: this.createdBy,
      createdOn: this.createdOn,
      updatedBy: this.updatedBy,
      updatedOn: this.updatedOn,
      firstMarker: this.firstMarker,
      secondMarker: this.secondMarker,
      candidates: this.candidates,
      examCenterId: this.examCenterId,
      displayName: this.displayName,
      sequence: this.sequence,
      invoiceSequence: this.invoiceSequence,
      cancellationDate: this.cancellationDate,
      examId: this.examId,
      documentSerialNumber: this.documentSerialNumber,
    };
  }

  fromDataModel(data: any): NavigationExam {
    data.createdOn = data?.createdAt;
    data.updatedOn = data?.updatedAt;
    data.syllabus = data.syllabusId ? Syllabus.prototype.toDto(data.syllabusId) : null;
    data.module = data.moduleId ? Module.prototype.toDto(data.moduleId) : null;
    data.firstMarker = data.firstMarkerId ? new User(data.firstMarkerId) : null;
    data.secondMarker = data.secondMarkerId ? new User(data.secondMarkerId) : null;
    data.examCenterId = data.examCenterId ? new ExamCenter(data.examCenterId) : null;
    // data.candidates = data.candidates ? new CandidateCollection(data.candidates.map((item: any) => new Candidate(item))) : new CandidateCollection([])
    return new NavigationExam(data);
  }

  isStatus(type: string): boolean {
    return this.status === type;
  }

  isScheduled(): boolean {
    return this.isStatus(NavigationExamStatusLabels.SCHEDULED);
  }

  isGenerated(): boolean {
    return this.isStatus(NavigationExamStatusLabels.GENERATED);
  }

  isSubmitted(): boolean {
    return this.isStatus(NavigationExamStatusLabels.SUBMITTED);
  }

  isMarking(): boolean {
    return this.isGenerated() || this.isStatus(NavigationExamStatusLabels.REVISION) || this.isStatus(NavigationExamStatusLabels.REVISIONMCA);
  }

  isReadyToRelease(): boolean {
    return this.isSubmitted() || this.isStatus(NavigationExamStatusLabels.REVISIONMCA);
  }

  isReleased(): boolean {
    return this.isStatus(NavigationExamStatusLabels.RELEASED);
  }

  isCancelled(): boolean {
    return this.isStatus(NavigationExamStatusLabels.CANCELLED);
  }

  isMarkingActivated(): boolean {
    return this.isMarking() || this.isSubmitted() || this.isReleased();
  }

  canEdit(user: User): boolean {
    return user.isType(UserTypesLabels.IAMIExamAdmin)
  }

  isSoftDeadlineReached(): boolean {
    const dayAfter = moment().add(72, 'hour');
    return dayAfter.isAfter(this.date);
  }

  isDeadlineReached(): boolean {
    const dayAfter = moment().add(1, 'day');
    return dayAfter.isAfter(this.date);
  }

  isLateRequest(): boolean {
    const weekAfter = moment(this.createdOn).add(1, 'week');
    return weekAfter.isAfter(this.date);
  }

  isLateCancellation(): boolean {
    const timeLimit = moment(this.cancellationDate).add(72, 'hour');
    return this.isCancelled() && timeLimit.isAfter(this.date);
  }

  isCancellable(): boolean {
    const timeLimit = moment(this.date).subtract(72, 'hour');
    return !this.isCancelled() && !moment().isAfter(timeLimit);
  }

  isReadyForDownload(): boolean {
    switch (this.syllabus?.code) {
      case 'S02':
        return this.isReadyForDownloadEKES();
      default:
        return this.isReadyForDownloadYDES();
    }
  }

  hasCandidateTypes(): boolean {
    return this.syllabus?.code == 'S02' && this.module?.code == 'M04';
  }

  private isReadyForDownloadEKES(): boolean {
    const examDate = moment(this.date);
    const hours = examDate.isoWeekday() == 1 ? 72 : 24;
    return this.isReadyForDownloadByHours(hours) && this.hasCandidates();
  }

  private isReadyForDownloadYDES(): boolean {
    return this.isReadyForDownloadByHours(4) && this.hasCandidates();
  }

  private isReadyForDownloadByHours(hours: number): boolean {
    const hourLimit = moment().add(hours, 'hour');
    const examFinish = moment(this.date).add(2, 'hour');
    const isEarly = hourLimit.isBefore(this.date);
    const isLate = moment().isAfter(examFinish);
    return !isEarly && !isLate;
  }

  private hasCandidates(): boolean {
    if (Array.isArray(this.candidates)) {
      return this.candidates.length > 0;
    }
    return this.candidates instanceof CandidateCollection && this.candidates.length() > 0;
  }

  getExamNumber(): string {
    return `${this.syllabus?.code}-${this.module?.code}-${moment(this.date).year()}${String(this.sequence).padStart(4, '0')}`
  }

}

export default NavigationExam;
