import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CardBody, CardHeader, CardTitle, Row, Card, Col } from 'reactstrap';

import { AppState } from 'store/store';
import ExamCenterDateFilteredTable from '../../filteredComponents/examCenterDateFilteredTable/examCenterDateFilteredTable';
import TreasurerDashboardApi from 'api/TreasurerDashboardApi';
import {
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
  sortCurrencyTable,
  sortDateTable,
} from 'shared/utils/dateTime';
import { formatCurrency } from 'shared/utils/currency';
import { defaultCurrency } from 'lib/common/models/transaction';
import { UserTypesLabels } from 'lib/common/models/user';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import {
  downloadCertificateInvoiceReceipt,
  downloadInvoiceReceipt,
} from 'shared/utils/ApiCommands';

const InvoiceReport: FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  const columns = useMemo(() => {
    const columns = [
      {
        Header: 'DATE',
        accessor: (d: any) => formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
        sortable: true,
        sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
      },
      { Header: 'INVOICE NUMBER', accessor: 'invoiceNumber', sortable: true },
      {
        Header: 'SUBTOTAL',
        accessor: (d: any) => formatCurrency(d.amount, defaultCurrency),
        sortable: true,
        sortType: (a: any, b: any) => sortCurrencyTable(a, b, 'amount'),
      },
      {
        Header: 'ACTIONS',
        Cell: (row: any) => (
          <DropdownButton variant="default" title="Actions" size="sm">
            <Dropdown.Item
              onClick={() => {
                const token = loggedUser.token;
                if (row.row.original.examId) {
                  downloadInvoiceReceipt(
                    { id: row.row.original.examId },
                    token,
                  );
                } else if (row.row.original.certificateId) {
                  downloadCertificateInvoiceReceipt(
                    row.row.original.certificateId,
                    token,
                    row.row.original.invoiceNumber
                  );
                }
              }}>
              <i className="nc-icon nc-cloud-download-93" />
              &nbsp;&nbsp;DOWNLOAD INVOICE
            </Dropdown.Item>
          </DropdownButton>
        ),
      },
    ];
    if (loggedUser.type !== UserTypesLabels.examCentre) {
      columns.unshift({
        Header: 'EXAM CENTRE',
        accessor: 'examCentre',
        sortable: true,
      });
    }
    return columns;
  }, [loggedUser.type]);

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Invoices Report</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <ExamCenterDateFilteredTable
              {...{ columns }}
              fetchMethod={TreasurerDashboardApi.getInvoicesReport}
              examCentreId={
                loggedUser.type === UserTypesLabels.examCentre
                  ? loggedUser.entityId
                  : undefined
              }
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default InvoiceReport;
